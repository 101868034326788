<template>
  <div class="col-xs-12 col-sm-6">
    <template v-if="selectedAddress">
      <h6>
        {{ selectedAddress.firstName + ' ' + selectedAddress.lastName }}
        <span v-if="selectedAddress.isDefault" class="default q-ml-sm">
          Default
        </span>
        <a
          href="javascript:void(0);"
          @click="
            () => {
              if (this.isMobile)
                this.changeDialogState({
                  dialog: 'addressListDialog',
                  val: true,
                  parentDialog: 'reserveTimeslot',
                })
              else this.updateField('changeAddress', true)
            }
          "
          class="text-dark q-ml-sm"
        >
          Change Address
        </a>
      </h6>
      <p v-html="$options.filters.formatAddress(selectedAddress)" />
    </template>
    <template v-else>
      <div class="row" v-if="isLoggedIn">
        <div class="col-xs-12 col-sm-6 col-md-6 offset-sm-3 addbtn">
          <q-btn
            rounded
            no-caps
            unelevated
            outline
            color="tertiary"
            label="Add Address"
            class="q-mt-md full-width"
            size="1rem"
            @click="
              () => {
                if (this.isMobile)
                  this.changeDialogState({
                    dialog: 'addShippingAddress',
                    val: true,
                    parentDialog: 'reserveTimeslot',
                  })
                else {
                  this.updateField('changeAddress', false)
                  this.updateField('addAddress', true)
                }
              }
            "
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentAddress',
  computed: {
    selectedAddress() {
      return this.selectedAddressForFulfillmentCode(this.fulfillmentCode)
    },
    ...mapGetters('address', ['selectedAddressForFulfillmentCode']),
  },
  props: {
    updateField: {
      type: Function,
      default: () => {},
    },
    fulfillmentCode: {
      type: String,
      default: '',
    },
  },
}
</script>
