var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-6"},[(_vm.selectedAddress)?[_c('h6',[_vm._v("\n      "+_vm._s(_vm.selectedAddress.firstName + ' ' + _vm.selectedAddress.lastName)+"\n      "),(_vm.selectedAddress.isDefault)?_c('span',{staticClass:"default q-ml-sm"},[_vm._v("\n        Default\n      ")]):_vm._e(),_c('a',{staticClass:"text-dark q-ml-sm",attrs:{"href":"javascript:void(0);"},on:{"click":() => {
            if (this.isMobile)
              this.changeDialogState({
                dialog: 'addressListDialog',
                val: true,
                parentDialog: 'reserveTimeslot',
              })
            else this.updateField('changeAddress', true)
          }}},[_vm._v("\n        Change Address\n      ")])]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatAddress(_vm.selectedAddress))}})]:[(_vm.isLoggedIn)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-6 offset-sm-3 addbtn"},[_c('q-btn',{staticClass:"q-mt-md full-width",attrs:{"rounded":"","no-caps":"","unelevated":"","outline":"","color":"tertiary","label":"Add Address","size":"1rem"},on:{"click":() => {
              if (this.isMobile)
                this.changeDialogState({
                  dialog: 'addShippingAddress',
                  val: true,
                  parentDialog: 'reserveTimeslot',
                })
              else {
                this.updateField('changeAddress', false)
                this.updateField('addAddress', true)
              }
            }}})],1)]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }